import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Title, Section, Text } from '../../components/Core'
import ExchTable from '../../components/Table/ExchTable'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import HomeCarousel from './HomeCarousel'
import 'isomorphic-fetch'
import { useStaticQuery, graphql } from 'gatsby'
import Link from '../../components/Link'
import { fetchHeaderCounts } from '../../store/actions/exchDataActions'

const LoggedInHero = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const userSettings = useSelector(state => state.user.user_settings)
  const headerCounts = useSelector(state => state.exchData.headerCounts)
  const userDarkMode =
    typeof userSettings !== 'undefined' ? userSettings.dark_mode : false

  const [windowWidth, setWindowWidth] = useState()
  // const [headerCounts, setHeaderCounts] = useState(false)

  const metaData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            languages
            title
          }
        }
      }
    `
  )

  const langs = metaData.site.siteMetadata.languages
  let i18nLang
  let lang

  if (typeof i18n.language !== 'undefined') {
    i18nLang = i18n.language.includes('-')
      ? i18n.language.split('-')[0]
      : i18n.language
  }

  lang =
    typeof langs.find(lang => lang === i18nLang) !== 'undefined'
      ? langs.find(lang => lang === i18nLang)
      : 'en'

  useEffect(() => {
    dispatch(fetchHeaderCounts())
  }, [dispatch])

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setWindowWidth(window.innerWidth)
      window.onresize = () => {
        setWindowWidth(window.innerWidth)
      }
    }
  }, [])
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section bg={userDarkMode ? 'black' : 'bg'} className="position-relative">
        <Container>
          <Row className="py-lg-5 py-5 my-lg-2 pt-lg-4 mt-5">
            <Col>
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <Title
                  variant="hero-home"
                  color={userDarkMode ? '#FAFAFA' : '#185398'}
                >
                  {t('landing.welcomeBack')}
                </Title>
                {windowWidth > 991 ? (
                  <HomeCarousel />
                ) : windowWidth < 991 && headerCounts !== false ? (
                  <Container className="mt-5">
                    <Row>
                      <Link to={`/${lang}/exchanges`}>
                        <div className="mr-5">
                          <Text
                            style={{ textDecoration: 'underline' }}
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                          >
                            {headerCounts.totalActiveExchanges}
                          </Text>
                          <Text
                            style={{ textDecoration: 'underline' }}
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                          >
                            {t('exchanges')}
                          </Text>
                        </div>
                      </Link>
                      <Link to={`/${lang}/currencies`}>
                        <div className="mr-5">
                          <Text
                            style={{ textDecoration: 'underline' }}
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                          >
                            {headerCounts.totalActiveCurrencies}
                          </Text>
                          <Text
                            style={{ textDecoration: 'underline' }}
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                          >
                            {t('currencies')}
                          </Text>
                        </div>
                      </Link>
                      <Link to={`/${lang}/markets`}>
                        <div>
                          <Text
                            style={{ textDecoration: 'underline' }}
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                          >
                            {headerCounts.totalActiveMarkets}
                          </Text>
                          <Text
                            style={{ textDecoration: 'underline' }}
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                          >
                            {t('markets')}
                          </Text>
                        </div>
                      </Link>
                    </Row>
                  </Container>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg="12">
              <ExchTable />
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default LoggedInHero
