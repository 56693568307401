import * as styles from './Carousel.module.css'
import React, { useEffect, useState } from 'react'
import { Row, Col, Carousel, Button, Spinner } from 'react-bootstrap'
import { Text, Box } from '../../components/Core'
import { useTranslation } from 'react-i18next'

import {
  fetchFavMarketIds,
  fetchUserBalances,
  fetchUserOpenOrders,
} from '../../store/actions/userActions'
import {
  fetchMarkets,
  fetchFavMarkets,
} from '../../store/actions/marketsActions'
import { useStaticQuery, graphql } from 'gatsby'
import Link from '../../components/Link'
import { useSelector, useDispatch } from 'react-redux'
import { Sparklines, SparklinesLine } from 'react-sparklines'
import theme from '../../utils/theme'
import styled from 'styled-components'
import { color, background } from 'styled-system'

const Selector = styled.div`
  background-color: ${props => (props.userDarkMode ? '#191B20' : '#E0E0E0')};
  border-radius: 28px;
  padding: 4px;
  display: inline-flex;
  > button {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    border-radius: 22px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      color: ${props =>
        props.userDarkMode
          ? theme.colors.primaryLight
          : theme.colors.primary} !important;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem
        ${props =>
          props.userDarkMode
            ? theme.colors.primaryLight
            : theme.colors.primary} !important;
    }
    ${color};
  }
  ${background};
`

const HomeCarousel = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const userSettings = useSelector(state => state.user.user_settings)
  const userDarkMode =
    typeof userSettings !== 'undefined' ? userSettings.dark_mode : false
  const markets = useSelector(state => state.markets.markets)
  const favMarkets = useSelector(state => state.markets.favMarkets)
  const btcExchRate = useSelector(state => state.currencies.btcExchRate)
  const currSign = useSelector(state => state.user.currSign)
  const userFavMarkets = useSelector(state => state.user.favMarketIds)
  const userBalances = useSelector(state => state.user.balances)
  const openOrders = useSelector(state => state.user.openOrders)
  const favMarketIds =
    typeof userFavMarkets !== 'undefined' && userFavMarkets.length > 0
      ? userFavMarkets.map(mkt => mkt.exchmktId)
      : []
  const [filteredMarkets, setFilteredMarkets] = useState(favMarkets)
  let favMarketStringIds = favMarketIds.toString()
  const [selectedFilter, setSelectedFilter] = useState('favs')
  const [actionsTriggered, setActionsTriggered] = useState(false)
  const [completed, setCompleted] = useState(false)

  const metaData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            languages
          }
        }
      }
    `
  )
  const langs = metaData.site.siteMetadata.languages
  let i18nLang
  let lang

  if (typeof i18n.language !== 'undefined') {
    i18nLang = i18n.language.includes('-')
      ? i18n.language.split('-')[0]
      : i18n.language
  }

  lang =
    typeof langs.find(lang => lang === i18nLang) !== 'undefined'
      ? langs.find(lang => lang === i18nLang)
      : 'en'

  useEffect(() => {
    dispatch(fetchMarkets(null, 'Favorites', 'desc', '50', '1', 'SevenDay'))
    dispatch(fetchFavMarketIds())
    dispatch(fetchUserBalances())
    dispatch(fetchUserOpenOrders())
    setActionsTriggered(true)
  }, [dispatch])

  useEffect(() => {
    const timer = setTimeout(() => {
      setCompleted(true)
    }, 9000)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (favMarketIds.length > 0) {
      dispatch(
        fetchFavMarkets(
          null,
          'Favorites',
          'desc',
          favMarketIds.length,
          1,
          'SevenDay',
          favMarketStringIds
        )
      )
    }
  }, [dispatch, favMarketIds.length, favMarketStringIds, markets])

  useEffect(() => {
    setFilteredMarkets(favMarkets)
  }, [favMarkets, markets])

  return (
    <div>
      <Selector
        className="nav mt-3"
        background={userDarkMode ? '#191B20' : '#E0E0E0'}
      >
        <Button
          disabled={
            actionsTriggered === true && filteredMarkets.length < 1
              ? true
              : false
          }
          style={{
            borderColor: 'transparent',
            backgroundColor:
              userDarkMode === true && selectedFilter === 'favs'
                ? '#2B2F36'
                : userDarkMode === false && selectedFilter === 'favs'
                ? '#FFFFFF'
                : 'transparent',
            color:
              userDarkMode === true && selectedFilter === 'favs'
                ? '#FFFFFF'
                : userDarkMode === false && selectedFilter === 'favs'
                ? '#000000'
                : userDarkMode === true
                ? '#9A9B9D'
                : '#636363',
          }}
          role="tab"
          aria-selected="true"
          aria-controls="panel-1"
          id="tab-1"
          tabIndex={0}
          onKeyDown={() => setSelectedFilter('favs')}
          onClick={() => setSelectedFilter('favs')}
        >
          {t('favoriteMarkets')}
        </Button>

        <Button
          disabled={
            actionsTriggered === true && userBalances.length < 1 ? true : false
          }
          style={{
            borderColor: 'transparent',
            backgroundColor:
              userDarkMode === true && selectedFilter === 'balances'
                ? '#2B2F36'
                : userDarkMode === false && selectedFilter === 'balances'
                ? '#FFFFFF'
                : 'transparent',
            color:
              userDarkMode === true && selectedFilter === 'balances'
                ? '#FFFFFF'
                : userDarkMode === false && selectedFilter === 'balances'
                ? '#000000'
                : userDarkMode === true
                ? '#9A9B9D'
                : '#636363',
          }}
          role="tab"
          aria-selected="false"
          aria-controls="panel-2"
          id="tab-2"
          tabIndex={0}
          onClick={() => setSelectedFilter('balances')}
        >
          {t('balances')}
        </Button>
        <Button
          disabled={
            actionsTriggered === true && openOrders.length < 1 ? true : false
          }
          style={{
            borderColor: 'transparent',
            backgroundColor:
              userDarkMode === true && selectedFilter === 'openOrders'
                ? '#2B2F36'
                : userDarkMode === false && selectedFilter === 'openOrders'
                ? '#FFFFFF'
                : 'transparent',
            color:
              userDarkMode === true && selectedFilter === 'openOrders'
                ? '#FFFFFF'
                : userDarkMode === false && selectedFilter === 'openOrders'
                ? '#000000'
                : userDarkMode === true
                ? '#9A9B9D'
                : '#636363',
          }}
          tabIndex={0}
          role="tab"
          aria-selected="false"
          aria-controls="panel-3"
          id="tab-3"
          onKeyDown={() => setSelectedFilter('openOrders')}
          onClick={() => setSelectedFilter('openOrders')}
        >
          {t('openOrders')}
        </Button>
      </Selector>

      <Carousel
        className={
          userDarkMode === true ? styles.lightIndicators : styles.darkIndicators
        }
        controls={false}
        fade
      >
        {selectedFilter === 'favs' && filteredMarkets.length > 0 ? (
          <Carousel.Item className="active">
            <Row className="pl-2 mt-3 mb-5">
              {filteredMarkets.map((mkt, index) => {
                let marketNameLowCase = mkt.marketName.toLowerCase()
                let lineChartData = []
                let percentageChange = mkt.percentChange * 100
                typeof mkt.miniChartData !== 'undefined' &&
                mkt.miniChartData !== null
                  ? mkt.miniChartData.map(function (candle) {
                      return lineChartData.push(candle.price)
                    })
                  : (lineChartData = [])

                let last7DaysLines = (
                  <Sparklines
                    svgWidth={180}
                    svgHeight={60}
                    data={lineChartData}
                    style={{
                      marginLeft: 10,
                      opacity: 0.2,
                      position: 'absolute',
                      bottom: 10,
                      left: 0,
                    }}
                  >
                    <SparklinesLine
                      style={{ strokeWidth: 3, fill: 'none' }}
                      color={
                        percentageChange >= 0
                          ? userDarkMode
                            ? '#50AF7B'
                            : '#2D844B'
                          : userDarkMode
                          ? '#FB4C42'
                          : '#D0433B'
                      }
                    />
                  </Sparklines>
                )
                let last7Days = (
                  <Text
                    variant="small"
                    style={{
                      textAlign: 'right',
                      color:
                        percentageChange >= 0
                          ? userDarkMode
                            ? '#50AF7B'
                            : '#2D844B'
                          : userDarkMode
                          ? '#FB4C42'
                          : '#D0433B',
                      marginBottom: 0,
                      lineHeight: 1,
                    }}
                  >
                    {typeof percentageChange !== 'undefined'
                      ? percentageChange.toFixed(2)
                      : 0}
                    %
                  </Text>
                )
                let exchLogo = (
                  <img
                    width="18"
                    height="18"
                    style={{
                      marginLeft: '10px',
                      marginTop: '-6px',
                    }}
                    src={
                      process.env.WWW_URL +
                      `/assets/img/exchange/${mkt.exchCode}-icon.png`
                    }
                    alt={`${mkt.exchName} Logo`}
                    onError={e => {
                      e.target.onerror = null
                      e.target.src =
                        process.env.WWW_URL + `/assets/img/currency/empty.png`
                    }}
                  />
                )
                let currLogo = (
                  <img
                    width="24"
                    height="24"
                    src={
                      process.env.WWW_URL +
                      `/assets/img/currency/${mkt.baseCurrencyCode}.png`
                    }
                    alt={`${mkt.baseCurrencyCode} Logo`}
                    onError={e => {
                      e.target.onerror = null
                      e.target.src =
                        process.env.WWW_URL + `/assets/img/currency/empty.png`
                    }}
                  />
                )
                let price =
                  typeof mkt.lastTradePrice !== 'undefined' &&
                  mkt.lastTradePrice < 1
                    ? mkt.quoteCurrencyCode +
                      ' ' +
                      mkt.lastTradePrice.toLocaleString(undefined, {
                        minimumFractionDigits: 8,
                      })
                    : typeof mkt.lastTradePrice !== 'undefined'
                    ? mkt.quoteCurrencyCode +
                      ' ' +
                      mkt.lastTradePrice.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })
                    : 0
                if (index >= 0 && index <= 4) {
                  return (
                    <Box
                      key={`${mkt.exchSlug}/${marketNameLowCase}`}
                      style={{
                        padding: 15,
                        margin: 10,
                        backgroundColor: userDarkMode ? '#191B20' : '#FFFFFF',
                        boxShadow: '0 2px 4px rgba(14, 86, 124, 0.17)',
                        borderRadius: 8,
                        cursor: 'pointer',
                        width: 204,
                        heigth: 100,
                        position: 'relative',
                      }}
                    >
                      {last7DaysLines}
                      <Link
                        aria-label={`${mkt.exchSlug}/${marketNameLowCase}`}
                        to={`/${lang}/markets/${mkt.exchSlug}/${marketNameLowCase}/`}
                      >
                        <Row className="mb-4">
                          <Col xs="3" className="text-center">
                            {currLogo}
                            {exchLogo}
                          </Col>
                          <Col xs="9" className="text-right">
                            <Text
                              style={{
                                color: userDarkMode ? '#FAFAFA' : '#191B20',
                                lineHeight: 1,
                              }}
                            >
                              <strong>{mkt.marketName}</strong>
                            </Text>
                            <Text
                              variant="small"
                              style={{
                                color: userDarkMode ? '#9A9B9D' : '#636363',
                              }}
                            >
                              {mkt.exchName}
                            </Text>
                          </Col>
                        </Row>
                        <Text
                          variant="small"
                          className="text-right"
                          style={{
                            color: userDarkMode ? '#FAFAFA' : '#191B20',
                          }}
                        >
                          {price}
                        </Text>
                        {last7Days}
                      </Link>
                    </Box>
                  )
                } else return null
              })}
            </Row>
          </Carousel.Item>
        ) : selectedFilter === 'favs' &&
          completed === false &&
          filteredMarkets.length < 1 ? (
          <div style={{ alignItems: 'center', minHeight: '200px' }}>
            <Spinner
              variant={userDarkMode ? 'light' : 'dark'}
              animation="border"
            />
          </div>
        ) : selectedFilter === 'favs' &&
          completed === true &&
          filteredMarkets.length < 1 ? (
          <div>No favorite markets found. Start adding some!</div>
        ) : null}

        {selectedFilter === 'favs' && filteredMarkets.length > 5 ? (
          <Carousel.Item>
            <Row className="pl-2 mt-3 mb-5">
              {filteredMarkets.map((mkt, index) => {
                let marketNameLowCase = mkt.marketName.toLowerCase()
                let lineChartData = []
                let percentageChange = mkt.percentChange * 100
                typeof mkt.miniChartData !== 'undefined' &&
                mkt.miniChartData !== null
                  ? mkt.miniChartData.map(function (candle) {
                      return lineChartData.push(candle.price)
                    })
                  : (lineChartData = [])

                let last7DaysLines = (
                  <Sparklines
                    svgWidth={180}
                    svgHeight={60}
                    data={lineChartData}
                    style={{
                      marginLeft: 10,
                      opacity: 0.2,
                      position: 'absolute',
                      bottom: 10,
                      left: 0,
                    }}
                  >
                    <SparklinesLine
                      style={{ strokeWidth: 3, fill: 'none' }}
                      color={
                        percentageChange >= 0
                          ? userDarkMode
                            ? '#50AF7B'
                            : '#2D844B'
                          : userDarkMode
                          ? '#FB4C42'
                          : '#D0433B'
                      }
                    />
                  </Sparklines>
                )
                let last7Days = (
                  <Text
                    variant="small"
                    style={{
                      textAlign: 'right',
                      color:
                        percentageChange >= 0
                          ? userDarkMode
                            ? '#50AF7B'
                            : '#2D844B'
                          : userDarkMode
                          ? '#FB4C42'
                          : '#D0433B',
                      marginBottom: 0,
                      lineHeight: 1,
                    }}
                  >
                    {typeof percentageChange !== 'undefined'
                      ? percentageChange.toFixed(2)
                      : 0}
                    %
                  </Text>
                )
                let exchLogo = (
                  <img
                    width="18"
                    height="18"
                    style={{
                      marginLeft: '10px',
                      marginTop: '-6px',
                    }}
                    src={
                      process.env.WWW_URL +
                      `/assets/img/exchange/${mkt.exchCode}-icon.png`
                    }
                    alt={`${mkt.exchName} Logo`}
                    onError={e => {
                      e.target.onerror = null
                      e.target.src =
                        process.env.WWW_URL + `/assets/img/currency/empty.png`
                    }}
                  />
                )
                let currLogo = (
                  <img
                    width="24"
                    height="24"
                    src={
                      process.env.WWW_URL +
                      `/assets/img/currency/${mkt.baseCurrencyCode}.png`
                    }
                    alt={`${mkt.baseCurrencyCode} Logo`}
                    onError={e => {
                      e.target.onerror = null
                      e.target.src =
                        process.env.WWW_URL + `/assets/img/currency/empty.png`
                    }}
                  />
                )
                let price =
                  typeof mkt.lastTradePrice !== 'undefined' &&
                  mkt.lastTradePrice < 1
                    ? mkt.quoteCurrencyCode +
                      ' ' +
                      mkt.lastTradePrice.toLocaleString(undefined, {
                        minimumFractionDigits: 8,
                      })
                    : typeof mkt.lastTradePrice !== 'undefined'
                    ? mkt.quoteCurrencyCode +
                      ' ' +
                      mkt.lastTradePrice.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })
                    : 0
                if (index > 4 && index <= 9) {
                  return (
                    <Box
                      key={`${mkt.exchSlug}/${marketNameLowCase}`}
                      style={{
                        padding: 15,
                        margin: 10,
                        backgroundColor: userDarkMode ? '#191B20' : '#FFFFFF',
                        boxShadow: '0 2px 4px rgba(14, 86, 124, 0.17)',
                        borderRadius: 8,
                        cursor: 'pointer',
                        width: 204,
                        heigth: 100,
                        position: 'relative',
                      }}
                    >
                      {last7DaysLines}
                      <Link
                        aria-label={`${mkt.exchSlug}/${marketNameLowCase}`}
                        to={`/${lang}/markets/${mkt.exchSlug}/${marketNameLowCase}/`}
                      >
                        <Row className="mb-4">
                          <Col xs="3" className="text-center">
                            {currLogo}
                            {exchLogo}
                          </Col>
                          <Col xs="9" className="text-right">
                            <Text
                              style={{
                                color: userDarkMode ? '#FAFAFA' : '#191B20',
                                lineHeight: 1,
                              }}
                            >
                              <strong>{mkt.marketName}</strong>
                            </Text>
                            <Text
                              variant="small"
                              style={{
                                color: userDarkMode ? '#9A9B9D' : '#636363',
                              }}
                            >
                              {mkt.exchName}
                            </Text>
                          </Col>
                        </Row>
                        <Text
                          variant="small"
                          className="text-right"
                          style={{
                            color: userDarkMode ? '#FAFAFA' : '#191B20',
                          }}
                        >
                          {price}
                        </Text>
                        {last7Days}
                      </Link>
                    </Box>
                  )
                } else return null
              })}
            </Row>
          </Carousel.Item>
        ) : null}

        {selectedFilter === 'favs' && filteredMarkets.length > 10 ? (
          <Carousel.Item>
            <Row className="pl-2 mt-3 mb-5">
              {filteredMarkets.map((mkt, index) => {
                let marketNameLowCase = mkt.marketName.toLowerCase()
                let lineChartData = []
                let percentageChange = mkt.percentChange * 100
                typeof mkt.miniChartData !== 'undefined' &&
                mkt.miniChartData !== null
                  ? mkt.miniChartData.map(function (candle) {
                      return lineChartData.push(candle.price)
                    })
                  : (lineChartData = [])

                let last7DaysLines = (
                  <Sparklines
                    svgWidth={180}
                    svgHeight={60}
                    data={lineChartData}
                    style={{
                      marginLeft: 10,
                      opacity: 0.2,
                      position: 'absolute',
                      bottom: 10,
                      left: 0,
                    }}
                  >
                    <SparklinesLine
                      style={{ strokeWidth: 3, fill: 'none' }}
                      color={
                        percentageChange >= 0
                          ? userDarkMode
                            ? '#50AF7B'
                            : '#2D844B'
                          : userDarkMode
                          ? '#FB4C42'
                          : '#D0433B'
                      }
                    />
                  </Sparklines>
                )
                let last7Days = (
                  <Text
                    variant="small"
                    style={{
                      textAlign: 'right',
                      color:
                        percentageChange >= 0
                          ? userDarkMode
                            ? '#50AF7B'
                            : '#2D844B'
                          : userDarkMode
                          ? '#FB4C42'
                          : '#D0433B',
                      marginBottom: 0,
                      lineHeight: 1,
                    }}
                  >
                    {typeof percentageChange !== 'undefined'
                      ? percentageChange.toFixed(2)
                      : 0}
                    %
                  </Text>
                )
                let exchLogo = (
                  <img
                    width="18"
                    height="18"
                    style={{
                      marginLeft: '10px',
                      marginTop: '-6px',
                    }}
                    src={
                      process.env.WWW_URL +
                      `/assets/img/exchange/${mkt.exchCode}-icon.png`
                    }
                    alt={`${mkt.exchName} Logo`}
                    onError={e => {
                      e.target.onerror = null
                      e.target.src =
                        process.env.WWW_URL + `/assets/img/currency/empty.png`
                    }}
                  />
                )
                let currLogo = (
                  <img
                    width="24"
                    height="24"
                    src={
                      process.env.WWW_URL +
                      `/assets/img/currency/${mkt.baseCurrencyCode}.png`
                    }
                    alt={`${mkt.baseCurrencyCode} Logo`}
                    onError={e => {
                      e.target.onerror = null
                      e.target.src =
                        process.env.WWW_URL + `/assets/img/currency/empty.png`
                    }}
                  />
                )
                let price =
                  typeof mkt.lastTradePrice !== 'undefined' &&
                  mkt.lastTradePrice < 1
                    ? mkt.quoteCurrencyCode +
                      ' ' +
                      mkt.lastTradePrice.toLocaleString(undefined, {
                        minimumFractionDigits: 8,
                      })
                    : typeof mkt.lastTradePrice !== 'undefined'
                    ? mkt.quoteCurrencyCode +
                      ' ' +
                      mkt.lastTradePrice.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })
                    : 0
                if (index > 9 && index <= 14) {
                  return (
                    <Box
                      key={`${mkt.exchSlug}/${marketNameLowCase}`}
                      style={{
                        padding: 15,
                        margin: 10,
                        backgroundColor: userDarkMode ? '#191B20' : '#FFFFFF',
                        boxShadow: '0 2px 4px rgba(14, 86, 124, 0.17)',
                        borderRadius: 8,
                        cursor: 'pointer',
                        width: 204,
                        heigth: 100,
                        position: 'relative',
                      }}
                    >
                      {last7DaysLines}
                      <Link
                        aria-label={`${mkt.exchSlug}/${marketNameLowCase}`}
                        to={`/${lang}/markets/${mkt.exchSlug}/${marketNameLowCase}/`}
                      >
                        <Row className="mb-4">
                          <Col xs="3" className="text-center">
                            {currLogo}
                            {exchLogo}
                          </Col>
                          <Col xs="9" className="text-right">
                            <Text
                              style={{
                                color: userDarkMode ? '#FAFAFA' : '#191B20',
                                lineHeight: 1,
                              }}
                            >
                              <strong>{mkt.marketName}</strong>
                            </Text>
                            <Text
                              variant="small"
                              style={{
                                color: userDarkMode ? '#9A9B9D' : '#636363',
                              }}
                            >
                              {mkt.exchName}
                            </Text>
                          </Col>
                        </Row>
                        <Text
                          variant="small"
                          className="text-right"
                          style={{
                            color: userDarkMode ? '#FAFAFA' : '#191B20',
                          }}
                        >
                          {price}
                        </Text>
                        {last7Days}
                      </Link>
                    </Box>
                  )
                } else return null
              })}
            </Row>
          </Carousel.Item>
        ) : null}

        {selectedFilter === 'balances' && userBalances.length > 0 ? (
          <Carousel.Item className="active">
            <Row className="pl-2 mt-3 mb-5">
              {userBalances.map((balance, index) => {
                let currLogo = (
                  <img
                    width="24"
                    height="24"
                    className="mr-2"
                    src={
                      process.env.WWW_URL +
                      `/assets/img/currency/${balance.balanceCurrImgName}.png`
                    }
                    alt={`${balance.balanceCurrImgName} Logo`}
                    onError={e => {
                      e.target.onerror = null
                      e.target.src =
                        process.env.WWW_URL + `/assets/img/currency/empty.png`
                    }}
                  />
                )
                let price =
                  typeof balance.lastPrice !== 'undefined' &&
                  balance.lastPrice * btcExchRate < 1
                    ? currSign +
                      ' ' +
                      (balance.lastPrice * btcExchRate).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 8,
                        }
                      )
                    : typeof balance.lastPrice !== 'undefined'
                    ? currSign +
                      ' ' +
                      (balance.lastPrice * btcExchRate).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 2,
                        }
                      )
                    : 0

                if (index >= 0 && index <= 4) {
                  return (
                    <Box
                      key={balance.balanceCurrCode}
                      style={{
                        padding: 15,
                        margin: 10,
                        backgroundColor: userDarkMode ? '#191B20' : '#FFFFFF',
                        boxShadow: '0 2px 4px rgba(14, 86, 124, 0.17)',
                        borderRadius: 8,
                        cursor: 'pointer',
                        width: 204,
                        heigth: 100,
                        position: 'relative',
                      }}
                    >
                      <Link
                        aria-label={balance.balanceCurrCode}
                        to={`/${lang}/balances/`}
                      >
                        <Row>
                          <Col xs="2">{currLogo}</Col>
                          <Col xs="10" className="text-right">
                            <Text
                              style={{
                                color: userDarkMode ? '#FAFAFA' : '#191B20',
                                lineHeight: 1.2,
                              }}
                            >
                              <strong>{balance.balanceCurrCode}</strong>
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-right">
                            <Text
                              className="mb-3"
                              variant="disclaimer"
                              style={{
                                color: userDarkMode ? '#9A9B9D' : '#636363',
                                lineHeight: 1.2,
                              }}
                            >
                              {balance.balanceCurrName}
                            </Text>
                            <Text
                              variant="small"
                              color={userDarkMode ? '#FAFAFA' : '#191B20'}
                              style={{
                                lineHeight: 1.5,
                              }}
                            >
                              {balance.balanceAmountTotal *
                                (balance.lastPrice * btcExchRate) >=
                              1
                                ? currSign +
                                  ' ' +
                                  (
                                    balance.balanceAmountTotal *
                                    (balance.lastPrice * btcExchRate)
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })
                                : currSign +
                                  ' ' +
                                  (
                                    balance.balanceAmountTotal *
                                    (balance.lastPrice * btcExchRate)
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 8,
                                  })}
                            </Text>
                            <Text
                              variant="small"
                              color={userDarkMode ? '#FAFAFA' : '#191B20'}
                              style={{
                                lineHeight: 1.5,
                              }}
                            >
                              {balance.balanceCurrCode}{' '}
                              {balance.balanceAmountTotal.toFixed(2)}
                            </Text>
                            <Text
                              style={{
                                color: userDarkMode ? '#9A9B9D' : '#636363',
                                lineHeight: 2,
                                fontSize: 13,
                              }}
                            >
                              {price} / 1 {balance.balanceCurrCode}
                            </Text>
                          </Col>
                        </Row>
                      </Link>
                    </Box>
                  )
                } else return null
              })}
            </Row>
          </Carousel.Item>
        ) : selectedFilter === 'balances' &&
          completed === false &&
          userBalances.length < 1 ? (
          <div style={{ alignItems: 'center', minHeight: '200px' }}>
            <Spinner
              variant={userDarkMode ? 'light' : 'dark'}
              animation="border"
            />
          </div>
        ) : selectedFilter === 'balances' &&
          completed === true &&
          userBalances.length < 1 ? (
          <div>No balances found!</div>
        ) : null}

        {selectedFilter === 'balances' && userBalances.length > 5 ? (
          <Carousel.Item>
            <Row className="pl-2 mt-3 mb-5">
              {userBalances.map((balance, index) => {
                let currLogo = (
                  <img
                    width="24"
                    height="24"
                    className="mr-2"
                    src={
                      process.env.WWW_URL +
                      `/assets/img/currency/${balance.balanceCurrImgName}.png`
                    }
                    alt={`${balance.balanceCurrImgName} Logo`}
                    onError={e => {
                      e.target.onerror = null
                      e.target.src =
                        process.env.WWW_URL + `/assets/img/currency/empty.png`
                    }}
                  />
                )
                let price =
                  typeof balance.lastPrice !== 'undefined' &&
                  balance.lastPrice * btcExchRate < 1
                    ? currSign +
                      ' ' +
                      (balance.lastPrice * btcExchRate).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 8,
                        }
                      )
                    : typeof balance.lastPrice !== 'undefined'
                    ? currSign +
                      ' ' +
                      (balance.lastPrice * btcExchRate).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 2,
                        }
                      )
                    : 0

                if (index > 4 && index <= 9) {
                  return (
                    <Box
                      key={balance.balanceCurrCode}
                      style={{
                        padding: 15,
                        margin: 10,
                        backgroundColor: userDarkMode ? '#191B20' : '#FFFFFF',
                        boxShadow: '0 2px 4px rgba(14, 86, 124, 0.17)',
                        borderRadius: 8,
                        cursor: 'pointer',
                        width: 204,
                        heigth: 100,
                        position: 'relative',
                      }}
                    >
                      <Link
                        aria-label={balance.balanceCurrCode}
                        to={`/${lang}/balances/`}
                      >
                        <Row>
                          <Col xs="2">{currLogo}</Col>
                          <Col xs="10" className="text-right">
                            <Text
                              style={{
                                color: userDarkMode ? '#FAFAFA' : '#191B20',
                                lineHeight: 1.2,
                              }}
                            >
                              <strong>{balance.balanceCurrCode}</strong>
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-right">
                            <Text
                              className="mb-3"
                              variant="disclaimer"
                              style={{
                                color: userDarkMode ? '#9A9B9D' : '#636363',
                                lineHeight: 1.2,
                              }}
                            >
                              {balance.balanceCurrName}
                            </Text>
                            <Text
                              variant="small"
                              color={userDarkMode ? '#FAFAFA' : '#191B20'}
                              style={{
                                lineHeight: 1.5,
                              }}
                            >
                              {balance.balanceAmountTotal *
                                (balance.lastPrice * btcExchRate) >=
                              1
                                ? currSign +
                                  ' ' +
                                  (
                                    balance.balanceAmountTotal *
                                    (balance.lastPrice * btcExchRate)
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })
                                : currSign +
                                  ' ' +
                                  (
                                    balance.balanceAmountTotal *
                                    (balance.lastPrice * btcExchRate)
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 8,
                                  })}
                            </Text>
                            <Text
                              variant="small"
                              color={userDarkMode ? '#FAFAFA' : '#191B20'}
                              style={{
                                lineHeight: 1.5,
                              }}
                            >
                              {balance.balanceCurrCode}{' '}
                              {balance.balanceAmountTotal.toFixed(2)}
                            </Text>
                            <Text
                              style={{
                                color: userDarkMode ? '#9A9B9D' : '#636363',
                                lineHeight: 2,
                                fontSize: 13,
                              }}
                            >
                              {price} / 1 {balance.balanceCurrCode}
                            </Text>
                          </Col>
                        </Row>
                      </Link>
                    </Box>
                  )
                } else return null
              })}
            </Row>
          </Carousel.Item>
        ) : null}

        {selectedFilter === 'balances' && userBalances.length > 10 ? (
          <Carousel.Item>
            <Row className="pl-2 mt-3 mb-5">
              {userBalances.map((balance, index) => {
                let currLogo = (
                  <img
                    width="24"
                    height="24"
                    className="mr-2"
                    src={
                      process.env.WWW_URL +
                      `/assets/img/currency/${balance.balanceCurrImgName}.png`
                    }
                    alt={`${balance.balanceCurrImgName} Logo`}
                    onError={e => {
                      e.target.onerror = null
                      e.target.src =
                        process.env.WWW_URL + `/assets/img/currency/empty.png`
                    }}
                  />
                )
                let price =
                  typeof balance.lastPrice !== 'undefined' &&
                  balance.lastPrice * btcExchRate < 1
                    ? currSign +
                      ' ' +
                      (balance.lastPrice * btcExchRate).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 8,
                        }
                      )
                    : typeof balance.lastPrice !== 'undefined'
                    ? currSign +
                      ' ' +
                      (balance.lastPrice * btcExchRate).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 2,
                        }
                      )
                    : 0

                if (index > 9 && index <= 14) {
                  return (
                    <Box
                      key={balance.balanceCurrCode}
                      style={{
                        padding: 15,
                        margin: 10,
                        backgroundColor: userDarkMode ? '#191B20' : '#FFFFFF',
                        boxShadow: '0 2px 4px rgba(14, 86, 124, 0.17)',
                        borderRadius: 8,
                        cursor: 'pointer',
                        width: 204,
                        heigth: 100,
                        position: 'relative',
                      }}
                    >
                      <Link
                        aria-label={balance.balanceCurrCode}
                        to={`/${lang}/balances/`}
                      >
                        <Row>
                          <Col xs="2">{currLogo}</Col>
                          <Col xs="10" className="text-right">
                            <Text
                              style={{
                                color: userDarkMode ? '#FAFAFA' : '#191B20',
                                lineHeight: 1.2,
                              }}
                            >
                              <strong>{balance.balanceCurrCode}</strong>
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-right">
                            <Text
                              className="mb-3"
                              variant="disclaimer"
                              style={{
                                color: userDarkMode ? '#9A9B9D' : '#636363',
                                lineHeight: 1.2,
                              }}
                            >
                              {balance.balanceCurrName}
                            </Text>
                            <Text
                              variant="small"
                              color={userDarkMode ? '#FAFAFA' : '#191B20'}
                              style={{
                                lineHeight: 1.5,
                              }}
                            >
                              {balance.balanceAmountTotal *
                                (balance.lastPrice * btcExchRate) >=
                              1
                                ? currSign +
                                  ' ' +
                                  (
                                    balance.balanceAmountTotal *
                                    (balance.lastPrice * btcExchRate)
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })
                                : currSign +
                                  ' ' +
                                  (
                                    balance.balanceAmountTotal *
                                    (balance.lastPrice * btcExchRate)
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 8,
                                  })}
                            </Text>
                            <Text
                              variant="small"
                              color={userDarkMode ? '#FAFAFA' : '#191B20'}
                              style={{
                                lineHeight: 1.5,
                              }}
                            >
                              {balance.balanceCurrCode}{' '}
                              {balance.balanceAmountTotal.toFixed(2)}
                            </Text>
                            <Text
                              style={{
                                color: userDarkMode ? '#9A9B9D' : '#636363',
                                lineHeight: 2,
                                fontSize: 13,
                              }}
                            >
                              {price} / 1 {balance.balanceCurrCode}
                            </Text>
                          </Col>
                        </Row>
                      </Link>
                    </Box>
                  )
                } else return null
              })}
            </Row>
          </Carousel.Item>
        ) : null}

        {selectedFilter === 'openOrders' && openOrders.length > 0 ? (
          <Carousel.Item className="active">
            <Row className="pl-2 mt-3 mb-5">
              {openOrders.map((order, index) => {
                let currLogo = (
                  <img
                    width="24"
                    height="24"
                    src={
                      process.env.WWW_URL +
                      `/assets/img/currency/${order.baseCurrency}.png`
                    }
                    alt={`${order.baseCurrency} Logo`}
                    onError={e => {
                      e.target.onerror = null
                      e.target.src =
                        process.env.WWW_URL + `/assets/img/currency/empty.png`
                    }}
                  />
                )
                let exchLogo = (
                  <img
                    width="18"
                    height="18"
                    style={{
                      marginLeft: '10px',
                      marginTop: '-6px',
                    }}
                    src={
                      process.env.WWW_URL +
                      `/assets/img/exchange/${order.exchCode}-icon.png`
                    }
                    alt={`${order.exchName} Logo`}
                    onError={e => {
                      e.target.onerror = null
                      e.target.src =
                        process.env.WWW_URL + `/assets/img/currency/empty.png`
                    }}
                  />
                )
                let price =
                  typeof order.limitPrice !== 'undefined' &&
                  order.limitPrice * btcExchRate < 1
                    ? currSign +
                      ' ' +
                      (order.limitPrice * btcExchRate).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 8,
                        }
                      )
                    : typeof order.limitPrice !== 'undefined'
                    ? currSign +
                      ' ' +
                      (order.limitPrice * btcExchRate).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 2,
                        }
                      )
                    : 0
                // let marketNameLowCase = order.marketName.toLowerCase();
                let date = order.openTime.split('T')
                if (index >= 0 && index <= 4) {
                  return (
                    <Box
                      key={order.coinigyOrderId}
                      style={{
                        padding: 15,
                        margin: 10,
                        backgroundColor: userDarkMode ? '#191B20' : '#FFFFFF',
                        boxShadow: '0 2px 4px rgba(14, 86, 124, 0.17)',
                        borderRadius: 8,
                        cursor: 'pointer',
                        width: 204,
                        heigth: 100,
                      }}
                    >
                      <Link
                        aria-label={`/${lang}/orders`}
                        to={`/${lang}/orders`}
                      >
                        <Row className="mb-3">
                          <Col xs="3" className="text-center">
                            {currLogo}
                            {exchLogo}
                          </Col>
                          <Col xs="9">
                            <Text
                              variant="small"
                              style={{
                                color: userDarkMode ? '#FAFAFA' : '#191B20',
                                lineHeight: 1,
                                marginTop: 5,
                              }}
                            >
                              <strong>{order.displayName}</strong>
                            </Text>
                            <Text
                              variant="small"
                              style={{
                                color: userDarkMode ? '#9A9B9D' : '#636363',
                              }}
                            >
                              {order.exchange}
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-center">
                            <Text
                              variant="small"
                              color={userDarkMode ? '#FAFAFA' : '#191B20'}
                              style={{
                                lineHeight: 1.5,
                              }}
                            >
                              {price}
                            </Text>
                            <Text
                              style={{
                                color: userDarkMode ? '#9A9B9D' : '#636363',
                                lineHeight: 2,
                                fontSize: 13,
                              }}
                            >
                              Placing {date[0]} {date[1].replace('Z', '')}
                            </Text>
                          </Col>
                        </Row>
                      </Link>
                    </Box>
                  )
                } else return null
              })}
            </Row>
          </Carousel.Item>
        ) : selectedFilter === 'openOrders' &&
          completed === false &&
          openOrders.length < 1 ? (
          <div style={{ alignItems: 'center', minHeight: '200px' }}>
            <Spinner
              variant={userDarkMode ? 'light' : 'dark'}
              animation="border"
            />
          </div>
        ) : selectedFilter === 'openOrders' &&
          completed === true &&
          openOrders.length < 1 ? (
          <div>No open orders found. Start adding some!</div>
        ) : null}
        {selectedFilter === 'openOrders' && openOrders.length > 5 ? (
          <Carousel.Item>
            <Row className="pl-2 mt-3 mb-5">
              {openOrders.map((order, index) => {
                let currLogo = (
                  <img
                    width="24"
                    height="24"
                    src={
                      process.env.WWW_URL +
                      `/assets/img/currency/${order.baseCurrency}.png`
                    }
                    alt={`${order.baseCurrency} Logo`}
                    onError={e => {
                      e.target.onerror = null
                      e.target.src =
                        process.env.WWW_URL + `/assets/img/currency/empty.png`
                    }}
                  />
                )
                let exchLogo = (
                  <img
                    width="18"
                    height="18"
                    style={{
                      marginLeft: '10px',
                      marginTop: '-6px',
                    }}
                    src={
                      process.env.WWW_URL +
                      `/assets/img/exchange/${order.exchCode}-icon.png`
                    }
                    alt={`${order.exchName} Logo`}
                    onError={e => {
                      e.target.onerror = null
                      e.target.src =
                        process.env.WWW_URL + `/assets/img/currency/empty.png`
                    }}
                  />
                )
                let price =
                  typeof order.limitPrice !== 'undefined' &&
                  order.limitPrice * btcExchRate < 1
                    ? currSign +
                      ' ' +
                      (order.limitPrice * btcExchRate).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 8,
                        }
                      )
                    : typeof order.limitPrice !== 'undefined'
                    ? currSign +
                      ' ' +
                      (order.limitPrice * btcExchRate).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 2,
                        }
                      )
                    : 0
                // let marketNameLowCase = order.marketName.toLowerCase();
                let date = order.openTime.split('T')
                if (index > 4 && index <= 9) {
                  return (
                    <Box
                      key={order.coinigyOrderId}
                      style={{
                        padding: 15,
                        margin: 10,
                        backgroundColor: userDarkMode ? '#191B20' : '#FFFFFF',
                        boxShadow: '0 2px 4px rgba(14, 86, 124, 0.17)',
                        borderRadius: 8,
                        cursor: 'pointer',
                        width: 204,
                        heigth: 100,
                      }}
                    >
                      <Link
                        aria-label={`/${lang}/orders`}
                        to={`/${lang}/orders`}
                      >
                        <Row className="mb-3">
                          <Col xs="3" className="text-center">
                            {currLogo}
                            {exchLogo}
                          </Col>
                          <Col xs="9">
                            <Text
                              variant="small"
                              style={{
                                color: userDarkMode ? '#FAFAFA' : '#191B20',
                                lineHeight: 1,
                                marginTop: 5,
                              }}
                            >
                              <strong>{order.displayName}</strong>
                            </Text>
                            <Text
                              variant="small"
                              style={{
                                color: userDarkMode ? '#9A9B9D' : '#636363',
                              }}
                            >
                              {order.exchange}
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-center">
                            <Text
                              variant="small"
                              color={userDarkMode ? '#FAFAFA' : '#191B20'}
                              style={{
                                lineHeight: 1.5,
                              }}
                            >
                              {price}
                            </Text>
                            <Text
                              style={{
                                color: userDarkMode ? '#9A9B9D' : '#636363',
                                lineHeight: 2,
                                fontSize: 13,
                              }}
                            >
                              Placing {date[0]} {date[1].replace('Z', '')}
                            </Text>
                          </Col>
                        </Row>
                      </Link>
                    </Box>
                  )
                } else return null
              })}
            </Row>
          </Carousel.Item>
        ) : null}
        {selectedFilter === 'openOrders' && openOrders.length > 10 ? (
          <Carousel.Item>
            <Row className="pl-2 mt-3 mb-5">
              {openOrders.map((order, index) => {
                let currLogo = (
                  <img
                    width="24"
                    height="24"
                    src={
                      process.env.WWW_URL +
                      `/assets/img/currency/${order.baseCurrency}.png`
                    }
                    alt={`${order.baseCurrency} Logo`}
                    onError={e => {
                      e.target.onerror = null
                      e.target.src =
                        process.env.WWW_URL + `/assets/img/currency/empty.png`
                    }}
                  />
                )
                let exchLogo = (
                  <img
                    width="18"
                    height="18"
                    style={{
                      marginLeft: '10px',
                      marginTop: '-6px',
                    }}
                    src={
                      process.env.WWW_URL +
                      `/assets/img/exchange/${order.exchCode}-icon.png`
                    }
                    alt={`${order.exchName} Logo`}
                    onError={e => {
                      e.target.onerror = null
                      e.target.src =
                        process.env.WWW_URL + `/assets/img/currency/empty.png`
                    }}
                  />
                )
                let price =
                  typeof order.limitPrice !== 'undefined' &&
                  order.limitPrice * btcExchRate < 1
                    ? currSign +
                      ' ' +
                      (order.limitPrice * btcExchRate).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 8,
                        }
                      )
                    : typeof order.limitPrice !== 'undefined'
                    ? currSign +
                      ' ' +
                      (order.limitPrice * btcExchRate).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 2,
                        }
                      )
                    : 0
                // let marketNameLowCase = order.marketName.toLowerCase();
                let date = order.openTime.split('T')
                if (index > 9 && index <= 14) {
                  return (
                    <Box
                      key={order.coinigyOrderId}
                      style={{
                        padding: 15,
                        margin: 10,
                        backgroundColor: userDarkMode ? '#191B20' : '#FFFFFF',
                        boxShadow: '0 2px 4px rgba(14, 86, 124, 0.17)',
                        borderRadius: 8,
                        cursor: 'pointer',
                        width: 204,
                        heigth: 100,
                      }}
                    >
                      <Link
                        aria-label={`/${lang}/orders`}
                        to={`/${lang}/orders`}
                      >
                        <Row className="mb-3">
                          <Col xs="3" className="text-center">
                            {currLogo}
                            {exchLogo}
                          </Col>
                          <Col xs="9">
                            <Text
                              variant="small"
                              style={{
                                color: userDarkMode ? '#FAFAFA' : '#191B20',
                                lineHeight: 1,
                                marginTop: 5,
                              }}
                            >
                              <strong>{order.displayName}</strong>
                            </Text>
                            <Text
                              variant="small"
                              style={{
                                color: userDarkMode ? '#9A9B9D' : '#636363',
                              }}
                            >
                              {order.exchange}
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-center">
                            <Text
                              variant="small"
                              color={userDarkMode ? '#FAFAFA' : '#191B20'}
                              style={{
                                lineHeight: 1.5,
                              }}
                            >
                              {price}
                            </Text>
                            <Text
                              style={{
                                color: userDarkMode ? '#9A9B9D' : '#636363',
                                lineHeight: 2,
                                fontSize: 13,
                              }}
                            >
                              Placing {date[0]} {date[1].replace('Z', '')}
                            </Text>
                          </Col>
                        </Row>
                      </Link>
                    </Box>
                  )
                } else return null
              })}
            </Row>
          </Carousel.Item>
        ) : null}
      </Carousel>
      <Row>
        <Col className="ml-1">
          {selectedFilter === 'balances' && userBalances.length > 0 ? (
            <Link to={`/${lang}/balances`}>
              <Text
                variant="small"
                color={userDarkMode ? '#3B96FF' : '#3377C6'}
              >
                {t('landing.allBalances')}
              </Text>
            </Link>
          ) : selectedFilter === 'favs' && filteredMarkets.length > 0 ? (
            <Link to={`/${lang}/markets`}>
              <Text
                variant="small"
                color={userDarkMode ? '#3B96FF' : '#3377C6'}
              >
                {t('landing.allMarkets')}
              </Text>
            </Link>
          ) : selectedFilter === 'openOrders' && openOrders.length > 0 ? (
            <Link to={`/${lang}/orders`}>
              <Text
                variant="small"
                color={userDarkMode ? '#3B96FF' : '#3377C6'}
              >
                {t('landing.allOrders')}
              </Text>
            </Link>
          ) : null}
        </Col>
      </Row>
    </div>
  )
}

export default HomeCarousel
